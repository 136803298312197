import myimage from "./assets/images/personalimage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./App.css";
import "./assets/css/stars.css";

function App() {
  return (
    <div className="App">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>

      <div className="App-content">
        <div className="left-sidebar">
          <img src={myimage} alt="mazhar" />
        </div>

        <header className="App-header">
          <div className="parent">
            <h1>MK</h1>
            <h1>MK</h1>
          </div>
          <div className="typewriter">
            <h1>Hello, I am Mazhar.</h1>
          </div>
          <ul className="social-icons">
            <li>
              <a
                href="https://twitter.com/Mazharkhan77"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/mazhar.fb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/mazhar-git"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/mazhar-usman"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </li>
          </ul>
        </header>
      </div>
    </div>
  );
}

export default App;
